import React from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import home_image from "./Assets/2148666181.jpg";
import Grades from "./Components/Grades";
import { Link } from "react-router-dom";
import Courses from "./Components/Courses";
import Paper from "./Components/Paper";

function Home() {
  return (
    <>
      <div className="w-full min-h-screen">
        <Navbar />
        {/* <div className='relative flex items-center justify-center w-full overflow-hidden bg-black h-36 md:h-96'>
            <img className='w-full h-screen opacity-40 cover-fill md:h-auto' alt='' src={home_image}/>

            <div className='absolute flex flex-col items-center'>
                <h1 className='text-xl font-bold text-white md:text-4xl'>LEARN EVERYTHING</h1>
                <p className='mt-3 text-sm font-bold text-[11px] md:text-[16px] text-center md:text-left text-white'>Online Courses - Interactive Tuition Classes - A/L, O/L Lessons</p>
                <Link to='/manage-courses'>
                <button className='px-4 py-2 bg-[#1C79BF] hover:text-white rounded-md mt-1'>See Courses</button>
                </Link>
            </div>
        </div> */}
        {/* <div className='relative flex items-center justify-center w-full overflow-hidden bg-black h-36 md:h-96' style={{ backgroundImage: `url(${home_image})`, backgroundSize: 'cover', backgroundPosition: 'top', backgroundRepeat: 'no-repeat' }}>
          <div className='absolute flex flex-col items-center'>
              <h1 className='text-xl font-bold text-white md:text-4xl'>LEARN EVERYTHING</h1>
              <p className='mt-3 text-sm font-bold text-[11px] md:text-[16px] text-center md:text-left text-white'>Online Courses - Interactive Tuition Classes - A/L, O/L Lessons</p>
              <Link to='/manage-courses'>
                  <button className='px-4 py-2 bg-[#1C79BF] hover:text-white rounded-md mt-1'>See Courses</button>
              </Link>
          </div>
        </div> */}
        <div
          //   className="relative w-full overflow-hidden h-36 md:h-96"
          className="relative w-full overflow-hidden h-52 md:h-96"
          style={{
            backgroundImage: `url(${home_image})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-40"></div>{" "}
          {/* Black background */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="flex flex-col items-center">
              <h1 className="text-3xl font-bold text-white md:text-8xl">
                LEARN EVERYTHING
              </h1>
              <p className="mt-3 text-sm font-bold text-[16px] md:text-[24px] text-center md:text-left text-white">
                Online Courses - Interactive Tuition Classes - A/L, O/L Lessons
              </p>
              <Link to="/manage-courses">
                <button className="px-4 py-2 bg-[#1C79BF] hover:text-white rounded-md mt-1 md:mt-10 md:text-2xl md:px-5 md:py-3">
                  See Courses
                </button>
              </Link>
            </div>
          </div>
        </div>

        <Grades />
        <Courses />
        {/* <div className='flex flex-col items-center py-5 mb-5 bg-blue-50'>
        <h2 className='text-2xl opacity-[75%]'>Find Us On</h2>
        <div className='flex gap-10 mt-3'>
            <div className='w-12 h-12 overflow-hidden cursor-pointer'><img width="96" height="96" src="https://img.icons8.com/color/96/linkedin.png" alt="linkedin"/></div>
            <div className='w-12 h-12 overflow-hidden cursor-pointer'><img width="96" height="96" src="https://img.icons8.com/color/96/youtube-play.png" alt="youtube-play"/></div>
            <div className='w-12 h-12 overflow-hidden cursor-pointer'><img width="96" height="96" src="https://img.icons8.com/color/96/facebook-new.png" alt="facebook-new"/></div>
            
        </div>
        </div> */}
      </div>
      <Paper/>
      <Footer />
    </>
  );
}

export default Home;
