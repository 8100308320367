import profile from "../Assets/unknown-person.jpg";
import { Link, useLocation } from "react-router-dom";
import { UserAuth } from "../../../Context/AuthContext";
import { useEffect, useState } from "react";
import { db } from "../../../Firebase/firebase-config";
import { getDoc, doc } from "firebase/firestore";
import { IoIosArrowDropleft } from "react-icons/io";
import logo from "../../Home/Assets/Logo.png";

const Sidebar1 = ({ isVisible, setVisible }) => {
  const { logout, user } = UserAuth();
  const [userName, setUserName] = useState();

  const location = useLocation();

  const hideSideBar = () => {
    setVisible(false);
  };

  // Function to determine if the link is active
  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await logout();
    } catch (handledError) {}
  };
  const authUserId = user && user?.uid;

  const email = user && user?.email;

  let displayEmail;
  if (email) {
    const emailParts = email.split("@");
    displayEmail = emailParts[0];
  } else {
    displayEmail = "No email available";
  }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (authUserId) {
          const userDocRef = doc(db, "users", authUserId);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const username = userData?.name ? userData.name : "Unknown";
            setUserName(username);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [authUserId, displayEmail]);

  return (
    // <div style={{ display: isVisible ? 'block' : 'none' }} className="absolute inset-y-0 left-0 flex flex-col w-64 h-screen px-2 space-y-6 text-white transition duration-200 ease-in-out transform -translate-x-full bg-gray-900 py-7 md:relative md:translate-x-0">
    <div
      className={`z-40 absolute inset-y-0 left-0 flex flex-col w-64 h-screen px-2 space-y-6 text-white transition duration-200 ease-in-out ${
        isVisible ? "transform -translate-x-0" : "-translate-x-full"
      } bg-gray-900  md:relative md:translate-x-0`}
    >
      <Link className="text-white text-[28px] md:ty-0 py-1 font-normal leading-normal flex items-center justify-end">
        <IoIosArrowDropleft className="md:hidden" onClick={hideSideBar} />
      </Link>
      <Link
        to="/"
        style={{ marginTop: 0 }}
        className="flex items-center justify-center w-full gap-3 py-1 bg-white rounded-md"
      >
        <img alt="" className="w-12" src={logo} />
        <h1 className="text-2xl text-[#1C79BF] font-bold">ICTSuhashi</h1>
      </Link>
      <Link
        to="/"
        className="flex flex-col items-center px-4 space-x-2 text-white"
      >
        <img
          className="relative flex w-20 h-20 overflow-hidden rounded-full shrink-0"
          src={profile}
          alt="profile"
        ></img>
        <span className="mt-2 text-2xl font-extrabold text-white">
          {userName || email?.split("@")[0].slice(0, 15)}
        </span>
      </Link>
      <nav>
        <h3 className="px-4 mb-3 text-xs uppercase">Main Menu</h3>
        <Link
          onClick={hideSideBar}
          to="/overview"
          className={`block py-2.5 px-4 rounded transition duration-200 ${
            isActive("/overview") ? "bg-blue-800 text-white" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block w-5 h-5 mr-2"
          >
            <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>{" "}
          Overview
        </Link>
        <Link
          onClick={hideSideBar}
          to="/manage-courses"
          className={`block py-2.5 px-4 rounded transition duration-200 ${
            isActive("/manage-courses") ? "bg-blue-800 text-white" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block w-5 h-5 mr-2"
          >
            <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
            <polyline points="2 17 12 22 22 17"></polyline>
            <polyline points="2 12 12 17 22 12"></polyline>
          </svg>{" "}
          Manage Courses
        </Link>
        <Link
          onClick={hideSideBar}
          to="/papers"
          className={`block py-2.5 px-4 rounded transition duration-200 ${
            isActive("/papers") ? "bg-blue-800 text-white" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block w-5 h-5 mr-2"
          >
            <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" x2="8" y1="13" y2="13"></line>
            <line x1="16" x2="8" y1="17" y2="17"></line>
            <line x1="10" x2="8" y1="9" y2="9"></line>
          </svg>{" "}
          Papers
        </Link>
        <Link
          onClick={hideSideBar}
          to="/paper-collection"
          className={`block py-2.5 px-4 rounded transition duration-200 ${
            isActive("/paper-collection") ? "bg-blue-800 text-white" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block w-5 h-5 mr-2"
          >
            <rect x="3" y="3" width="18" height="14" rx="2" ry="2"></rect>
            <line x1="8" y1="9" x2="16" y2="9"></line>
            <line x1="8" y1="13" x2="16" y2="13"></line>
            <path d="M5 19h14"></path>
            <path d="M7 22h10"></path>
          </svg>{" "}
          Paper Collection
        </Link>
        <Link
          onClick={hideSideBar}
          to="/payment-history-list"
          className={`block py-2.5 px-4 rounded transition duration-200 ${
            isActive("/payment-history-list") ? "bg-blue-800 text-white" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block w-5 h-5 mr-2"
          >
            <rect width="20" height="14" x="2" y="5" rx="2"></rect>
            <line x1="2" x2="22" y1="10" y2="10"></line>
          </svg>{" "}
          Payment History
        </Link>
      </nav>
      <nav>
        <h3 className="px-4 mb-3 text-xs uppercase">Explore</h3>
        {/* <Link
        onClick={hideSideBar}
          to="/about-tutor"
          className={`block py-2.5 px-4 rounded transition duration-200 ${
            isActive("/about-tutor")
              ? "bg-blue-800 text-white"
              : ""
          }`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block w-5 h-5 mr-2">
            <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>{" "}
          About Tutor
        </Link> */}
        <Link
          to="/inquiries"
          className={`block py-2.5 px-4 rounded transition duration-200 ${
            isActive("/inquiries") ? "bg-blue-800 text-white" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block w-5 h-5 mr-2"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <path d="M12 17h.01"></path>
          </svg>{" "}
          Inquiries
        </Link>
      </nav>
      <Link
        onClick={handleLogout}
        to="/logout"
        className="block py-2.5 px-4 rounded transition duration-200 hover:bg-blue-800 hover:text-white"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="inline-block w-5 h-5 mr-2"
        >
          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
          <polyline points="16 17 21 12 16 7"></polyline>
          <line x1="21" x2="9" y1="12" y2="12"></line>
        </svg>{" "}
        Logout Account
      </Link>
    </div>
  );
};

export default Sidebar1;
