import React from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../../Context/AuthContext";
import logo from "../Assets/Logo.png";
function Navbar() {
  const { user, logout } = UserAuth();
  console.log(user);

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await logout();
    } catch (handledError) {}
  };
  return (
    // <div className='flex items-center justify-between w-full h-16 px-3 border-b md:px-10 lg:px-56'>
    <div className="flex items-center justify-between w-full h-16 px-3 border-b 2xl:px-52">
      <Link to="/">
        <div className="flex items-center gap-2">
          <img src={logo} alt="logo" className="w-12 "></img>
          <h1 className="text-2xl font-bold text-[#1C79BF]">ICTSuhashi</h1>
        </div>
      </Link>
      <div className="hidden md:block">
        <ul className="flex gap-12">
          <Link to="/" className="text-xl font-medium hover:underline">
            Home
          </Link>
          <Link to="/overview" className="text-xl font-medium hover:underline">
            Dashboard
          </Link>
          <Link to="/about" className="text-xl font-medium hover:underline">
            About Us
          </Link>
        </ul>
      </div>
      <div className="flex gap-7">
        {/* <Link  to='/login' className='items-center hidden hover:underline md:flex md:text-[16px]'>
            Login
        </Link> */}
        {user ? (
          <Link
            onClick={handleLogout}
            className="bg-[#1C79BF] hover:text-white text-[18px] px-5 py-1.5 rounded-md  flex items-center"
          >
            Logout
          </Link>
        ) : (
          <Link
            to="/login"
            className="bg-[#1C79BF] hover:text-white text-[18px] px-5 py-1.5 rounded-md  flex items-center"
          >
            SignIn
          </Link>
        )}
      </div>
    </div>
  );
}

export default Navbar;
