
import Paper from './../Home/Components/Paper';
import { useState, useEffect } from 'react';
import { collection } from 'firebase/firestore';
import { getDocs } from 'firebase/firestore';
import { db } from './../../Firebase/firebase-config';
import Sidebar1 from './components/Sidebar1';
import Navbar1 from './components/Navbar1';
import { useParams, Link } from 'react-router-dom';
import CoursePopup from './components/CoursePopup';
import { truncateString } from './../../helpers/stringHelpers';
import Footer from './../Home/Components/Footer';

const PaperCollection = () => {
    const [paperCollections, setPaperCollections] = useState([]);
        const [loading, setLoading] = useState(false);
        const [isSidebarVisible, setSidebarVisible] = useState(false);
    
        const showSideBar = () => {
            setSidebarVisible(true);
          };
        
        
          const tabName = "/ Paper Collection";

        useEffect(() => {
            setLoading(true);
            const fetchPaperCollection = async () => {
                const paperCollection = collection(db, 'paper-collections');
                try {
                    const paperCollectionSnapshot = await getDocs(paperCollection);
                    const paperCollections = paperCollectionSnapshot.docs.map((paperDoc) => ({
                        id: paperDoc.id,
                        data: paperDoc.data()
                    }));
                    setPaperCollections(paperCollections);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.log(error);
                }
            }
            fetchPaperCollection();
        }, []);
    return (
        <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />
        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {paperCollections.length === 0 && !loading ? (
          <div className="flex flex-col items-center mt-10">
            <p>No data available. Please check back later.</p>
          </div>
        ) : (
          ""
        )}
        <div className="min-h-screen px-4 mx-auto my-2 ">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
            {paperCollections
              ?.sort((a, b) => {
                //Assuming 'dateTime' is a property of each edulevelDoc
                const dateTimeA = new Date(
                  `${a.data.date} ${a.data.time}`
                );
                const dateTimeB = new Date(
                  `${b.data.date} ${b.data.time}`
                );
                // return dateTimeB - dateTimeA;
                return dateTimeA - dateTimeB;
              })
              ?.map((paperCollection) => (
                <Link
                  className="w-full overflow-hidden border rounded-lg shadow-sm bg-card text-card-foreground"
                  to={`/paper-collection/${paperCollection.id}/papers`}
                  key={paperCollection.id}
                >
                  <div className="pb-5 ">
                    <div className="data-grid">
                      <img
                        src={paperCollection.data.url}
                        alt="Student"
                        className="w-full h-auto"
                        width={200}
                        height={100}
                        style={{ aspectRatio: "200 / 100", objectFit: "fill" }}
                      />
                      {/* <paperCollectionPopup obj={paperCollection.data} /> */}
                    </div>
                    <div className="mt-2 font-medium text-center">
                      {/* {paperCollection.paperCollection_data.name} */}
                      {truncateString(paperCollection.data.name, 20)}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
    )
}

export default PaperCollection;