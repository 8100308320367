import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { UserAuth } from "../../Context/AuthContext";
import { db } from "../../Firebase/firebase-config";
import { collection, query, where, getDocs } from "firebase/firestore";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import Footer from "../Home/Components/Footer";

const PaymentHistoryList = () => {
  const [payhistory, setPayHistory] = useState([]);
  const {user} = UserAuth();

  const userID = user && user?.uid;
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  };

  const tabName = "/ Payment History List";
  const fetchPaymentHistory = async () => {
    try {
      if (userID) {
        // Fetch payment history from 'payments_paper' collection
        const paperPaymentCollection = collection(db, "payments_paper");
        const paperQuerySnapshot = await getDocs(
          query(paperPaymentCollection, where("userId", "==", userID))
        );
        const paperPaymentData = paperQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          type:'paper'
        }));
  
        // Fetch payment history from 'payments' collection
        const paymentCollection = collection(db, "payments");
        const querySnapshot = await getDocs(
          query(paymentCollection, where("userId", "==", userID))
        );
        const paymentData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          type:'video'
        }));
  
        // Combine payment data from both collections
        const combinedPaymentData = [...paymentData, ...paperPaymentData];
  
        // Set the combined payment history
        setPayHistory(combinedPaymentData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching payment history:", error);
    }
  };
  

  useEffect(() => {
    setLoading(true);
    document.title = "Payments History List - ICT Suhashi";
    // Call the fetchPaymentHistory function when the component mounts
    fetchPaymentHistory();
  }, [userID]);



  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
      <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />
        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {(payhistory.length===0 && !loading)?(
          <div className='flex flex-col items-center mt-10'>
          <p>No data available. Please check back later.</p>
      </div>
        ):("")}

        <div className="min-h-screen px-4 mx-auto my-2 ">
          {payhistory &&
            payhistory
            ?.sort((a, b) => {
              //Assuming 'dateTime' is a property of each edulevelDoc
              const dateTimeA = new Date(`${a.date} ${a.time}`);
              const dateTimeB = new Date(`${b.date} ${b.time}`);
              return dateTimeB-dateTimeA
            })
              .map((paymentHistory) => (
                <Link
                  className="flex-1 p-4 my-2 bg-white rounded shadow-md md:flex md:items-center"
                  to={
                    paymentHistory.type === 'video'
                      ? `/payment-history/${paymentHistory.id}`
                      : `/payment-history-paper/${paymentHistory.id}`
                  }
                  key={paymentHistory.id}>
                  <div className="text-sm md:flex md:justify-between md:flex-auto">
                  <p>
                       {paymentHistory.title}
                    </p>
                    <p>
                       {paymentHistory.date}
                    </p>
                  </div>
                  <div className="justify-center mt-2 md:flex md:justify-end md:flex-1">
                    {paymentHistory.isAccept === true ? (
                      <div className=" bg-green-300 rounded-md inline-flex items-center w-40 justify-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent">
                        Paid
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="inline-block w-4 h-4 ml-1">
                          <polyline points="20 6 9 17 4 12" />
                        </svg>
                      </div>
                    ) : (
                      <div className="inline-flex items-center rounded-md w-40  justify-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-red-300">
                        Approval Pending
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="inline-block w-4 h-4 ml-1">
                          <circle cx="12" cy="12" r="10" />
                          <line x1="12" y1="8" x2="12" y2="12" />
                          <line x1="12" y1="16" x2="12" y2="16" />
                        </svg>
                      </div>
                    )}

                  </div>
                </Link>
              ))}
        </div>
        <Footer/>
      </div>
    </div>
  );
};

export default PaymentHistoryList;
